import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';

import { breakpoint } from 'ui/settings';
import { setSpace, setHeight, setType } from 'ui/mixins';
import { Fold, Helmet, Layout } from 'ui/partials';
import { Paragraph, Separator, Subtitle, Illustration, TextBlock, TextString, Title } from 'ui/components';

const Services = styled.ul`
  ${setType('x')};
  ${breakpoint.desktopUp} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const Service = styled.li`
  & > p,
  & > a {
    ${setSpace('mtm')};
  }
  ${breakpoint.phone} {
    margin-left: auto;
    margin-right: auto;
    max-width: 580px;
    text-align: center;
    &:not(:last-child) {
      ${setSpace('mbh')};
    }
  }
  ${breakpoint.tablet} {
    &:not(:last-child) {
      ${setSpace('mbh')};
    }
  }
  ${breakpoint.desktopUp} {
    ${({ last }) => (!last ? setSpace('mbh') : ``)};
    ${setSpace('prl')};
    flex: 0 0 ${100 / 3}%;
  }
`;

const StyledIllustration = styled.span`
  ${setSpace('mbm')};
  display: inline-block;
  svg {
    ${setHeight('l')};
  }
  ${breakpoint.phone} {
    svg {
      ${setHeight('h')};
    }
  }
`;

const ServicesPage = props => (
  <Fragment>
    <Helmet {...props} title="Services" />
    <Layout {...props}>
      <Fold>
        <TextBlock>
          <Title>
            <TextString looks="hcap">Services</TextString>
            <TextString looks="h1">Here to help you shape, design and build your next product</TextString>
          </Title>
          <Paragraph looks="p2">
            Equipped with skills to guide the discussion on product direction, design, development and testing, my
            expertise is of great help throughout the entire project lifecycle.
          </Paragraph>
        </TextBlock>
      </Fold>
      <Separator ornamental size="h" />
      <Services>
        <Service>
          <StyledIllustration>
            <Illustration name="research" />
          </StyledIllustration>
          <Subtitle looks="h5">User Research</Subtitle>
          <Paragraph looks="p4">
            I’m well capable of running qualitative research, creating persona profiles, writing user scenarios, product
            use cases and storyboards.
          </Paragraph>
        </Service>
        <Service>
          <StyledIllustration>
            <Illustration name="ia" />
          </StyledIllustration>
          <Subtitle looks="h5">Information Architecture</Subtitle>
          <Paragraph looks="p4">
            I’m good at analyzing and organizing data. I like to have my opinion on site content outline, enjoy creating
            site maps and content diagrams.
          </Paragraph>
        </Service>
        <Service>
          <StyledIllustration>
            <Illustration name="ixd" />
          </StyledIllustration>
          <Subtitle looks="h5">Interaction Design</Subtitle>
          <Paragraph looks="p4">
            From paper sketches to visual or living prototypes—I design easy to use and engaging interfaces for desktop
            and mobile.
          </Paragraph>
        </Service>
        <Service last>
          <StyledIllustration>
            <Illustration name="design" />
          </StyledIllustration>
          <Subtitle looks="h5">User Interface Design</Subtitle>
          <Paragraph looks="p4">
            I have an eye for detail, passion for color theory & psychology, enjoy playing with type and breaking the
            grid. I value simplicity and clarity.
          </Paragraph>
        </Service>
        <Service last>
          <StyledIllustration>
            <Illustration name="dev" />
          </StyledIllustration>
          <Subtitle looks="h5">Software Development</Subtitle>
          <Paragraph looks="p4">
            I create quick <abbr title="HyperText Markup Language">HTML</abbr>,{' '}
            <abbr title="Cascading Style Sheet">CSS</abbr> & <abbr title="JavaScript">JS</abbr> prototypes for early
            testing purposes as well as production-ready sophisticated front-end architectures.
          </Paragraph>
        </Service>
        <Service last>
          <StyledIllustration>
            <Illustration name="testing" />
          </StyledIllustration>
          <Subtitle looks="h5">Product Evaluation</Subtitle>
          <Paragraph looks="p4">
            I do heuristic analysis, moderate usability testing sessions and conduct project evaluation workshops with
            project stakeholders.
          </Paragraph>
        </Service>
      </Services>
    </Layout>
  </Fragment>
);

export default withTheme(ServicesPage);
